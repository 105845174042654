import styled from 'styled-components';
const DocumentStyle = styled.div` 
   
	color: #2C343E !important;
	.toc {
	  width: 80%;
	  box-sizing: border-box;
	  padding: 10px;
	  border: 1px solid #888;
	  margin-top: 20px;
	  margin-left: auto;
	  margin-right: auto;
	  margin-bottom: 0;
	  ol,ul>li{
		line-height: 2;
		color: #6b6b84;
		font-size: 16px;
		p {
			margin: 0;
		}	
	  }
	}
	a {
	  color: #007bff;
	  text-decoration: none;
	  background-color: transparent;
	}
	h1 {
		font-size: 2.3rem;
	}
	h2 {
		border-left: 6px solid #50C8A7;
		font-weight: bold;
		padding: 7px 0 7px 15px;
		margin: 100px 0 30px;
	}
	h3 {
	    border-left: 4px solid #d7dbdf;
		margin: 50px 0 20px !important;
		padding: 5px 0 5px 15px !important;
		font-weight: bold;
	}
	blockquote{
	    text-align: left;
		margin: 1em;
		padding: 25px !important;
		p{
			font-size: 1em !important;
			font-weight: initial;
		}
		::after {
			content:none;
		}
		::before {
			animation: none;
			font-size: 70px;
		}
	}
	
   
`;

export default DocumentStyle
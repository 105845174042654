import React from 'react';
import {graphql} from 'gatsby';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import Layout from '../components/App/Layout';
import DocumentDetail from '../components/Document/DocumentDetail';
import SEO from '../components/App/SEO';
import exampleMetaImage from '../assets/images/examples/example-meta-image.png';

const directoryObject = {
    documents: {
        url: '/documents',
        name: '使い方ガイド'
    },
    entries: {
        url: '/documents/entries',
        name: '応募方法'
    },
    'line-app': {
        url: '/documents/line-app',
        name: 'LINEでキャンペーン'
    }
};
export default function Template({data}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
    const subDirectories = frontmatter.slug.split('/')
        .filter((x, i)=> !!x && i<frontmatter.slug.split('/').length -1)
        .map(x=> {
            return directoryObject[x]
        });
    return (
      <Layout>
        <SEO
          title={`${frontmatter.title}`}
          desc={frontmatter.description}
          pathname={frontmatter.slug}
          type={'Article'}
          shortName={`${frontmatter.title}`}
          createdAt={frontmatter.createdAt}
          updatedAt={frontmatter.updatedAt}
          description={frontmatter.description}
        />
        <Navbar />
        <PageBanner
            pageTitle={frontmatter.title}
            homePageText="Home"
            homePageUrl="/"
            activePageText={frontmatter.title}
            subDirectories={subDirectories}
        />
        <DocumentDetail html={html}/>
        <Footer />
      </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        createdAt(formatString: "YYYY-MM-DD")
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        title
        description
      }
    }
  }
`;
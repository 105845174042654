import React from 'react';
import Sideber from './DocumentSidebar';
import Wrapper from './DocumentDetail.style';

const DocumentContent = (props) => {
    return (
        <Wrapper>
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{ __html: props.html }}
                        />
                    </div>

                    <div className="col-lg-3 col-md-12">
                        <Sideber />
                    </div>
                </div>
            </div>
        </section>
        </Wrapper>
    )
};

export default DocumentContent
import React from 'react'
import {Link} from 'gatsby'

const DocumentSidebar = () => {
    return (
        <div className="widget-area">

            <div className="widget widget_categories">
                <h3 className="widget-title">使い方</h3>

                <ul>
                    <li>
                        <Link to="/documents/campaign">
                            キャンペーン
                        </Link>
                    </li>
                    <li>
                        <Link to="/documents/instant-win">
                            インスタントウィン
                        </Link>
                    </li>
                    <li>
                        <Link to="/documents/gallery">
                            ギャラリー
                        </Link>
                    </li>
                    <li>
                        <Link to="/documents/line-app">
                            LINEでキャンペーン
                        </Link>
                    </li>
                    <li>
                        <Link to="/documents/invoice">
                            プラン・請求
                        </Link>
                    </li>
                    <li>
                        <Link to="/documents/user">
                            ユーザー情報
                        </Link>
                    </li>
                    <li>
                        <Link to="/documents/entries">
                            応募方法
                        </Link>
                        <ul style={{padding:'10px'}}>
                            <li><Link to="/documents/entries/twitter">Twitter</Link></li>
                            <li><Link to="/documents/entries/facebook">Facebook</Link></li>
                            <li><Link to="/documents/entries/instagram">Instagram</Link></li>
                            <li><Link to="/documents/entries/line">LINE</Link></li>
                            <li><Link to="/documents/entries/youtube">Youtube</Link></li>
                            <li><Link to="/documents/entries/hatena-bookmark">はてなブックマーク</Link></li>
                            <li><Link to="/documents/entries/twitcasting">ツイキャス</Link></li>
                            <li><Link to="/documents/entries/twitch">Twitch</Link></li>
                            <li><Link to="/documents/entries/apple-music">AppleMusic</Link></li>
                            <li><Link to="/documents/entries/spotify">Spotify</Link></li>
                            <li><Link to="/documents/entries/receipt">レシート</Link></li>
                            <li><Link to="/documents/entries/app">アプリ</Link></li>
                            <li><Link to="/documents/entries/question">質問</Link></li>
                            <li><Link to="/documents/entries/blog">ブログ</Link></li>
                            <li><Link to="/documents/entries/bonus">ボーナス</Link></li>
                            <li><Link to="/documents/entries/serial-number">シリアルナンバー</Link></li>
                            <li><Link to="/documents/entries/submit-media">画像／動画投稿</Link></li>
                            <li><Link to="/documents/entries/mail">メール</Link></li>
                            <li><Link to="/documents/entries/share">シェア</Link></li>
                            <li><Link to="/documents/entries/link">リンク</Link></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default DocumentSidebar